import './Home.css';

export default function Home() {
    return (
        <div className="Home">
            <img src="logo.png" className="Home-logo" alt="logo" />
            <div style={{height: "8vh"}} />
            <div className="Home-content">
                {/* <h1>Build intelligent robots</h1> */}
                <h1>Solve robot decision making</h1>

                <p>We enable robots to learn from their own experience, by pre-training components in sim.</p>

                {/* <p>We use multi-agent simulation to</p>
                <ul>
                    <li>test and fail fast</li>
                    <li>generate unlimited targeted data</li>
                </ul> */}

                <p>What many disagree with, and we are betting on:</p>
                <ul>
                    <li>Most training data for robot decision making will be simulated</li>
                    <li>Most robot limitations can be shown in sim</li>
                </ul>

                <a href="mailto:hello@sim2sim.tech">
                    <button className="contact-button">Contact us</button>
                </a>
            </div>
        </div>
    );
}